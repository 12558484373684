import {FORGOT_PASSWORD_ACTIONS, INITIAL_STATE} from './constants';

const forgotPasswordReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  const {error} = payload || {};

  switch(type) {
    case FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        error,
      };
    default:
      return state;
  };
};

export default forgotPasswordReducer;
