import keymirror from 'keymirror';

export const INITIAL_STATE = {
  qrCode: '',
  error: null,
  hospitals: [],
  accessRequests: [],
  meta: {
    page: 1,
    per_page: 10,
    total_pages: 1,
  },
};

export const USER_ACTIONS = keymirror({
  GET_QR: null,
  GET_QR_SUCCESS: null,
  GET_QR_ERROR: null,
  SEND_QR: null,
  SEND_QR_SUCCESS: null,
  SEND_QR_ERROR: null,
  GET_HOSPITALS_LIST: null,
  GET_HOSPITALS_LIST_SUCCESS: null,
  GET_HOSPITALS_LIST_NEXT_PAGE_SUCCESS: null,
  GET_HOSPITALS_LIST_ERROR: null,
  UPDATE_USER_REQUEST: null,
  UPDATE_USER_REQUEST_SUCCESS: null,
  UPDATE_USER_REQUEST_ERROR: null,
  GET_ACCESS_REQUESTS: null,
  GET_ACCESS_REQUESTS_SUCCESS: null,
  GET_ACCESS_REQUESTS_ERROR: null,
  UPDATE_REQUEST_STATE: null,
  UPDATE_REQUEST_STATE_SUCCESS: null,
  UPDATE_REQUEST_STATE_ERROR: null,
});
