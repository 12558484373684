import keymirror from 'keymirror';

export const INITIAL_STATE = {
	error: null,
	userData: null,
};

export const REGISTRATION_ACTIONS = keymirror({
	REGISTRATION_REQUEST: null,
	REGISTRATION_REQUEST_SUCCESS: null,
	REGISTRATION_REQUEST_ERROR: null,
	PHONE_VERIFICATION_REQUEST: null,
	PHONE_VERIFICATION_REQUEST_SUCCESS: null,
	PHONE_VERIFICATION_REQUEST_ERROR: null,
	CHECK_CODE_REQUEST: null,
	CHECK_CODE_REQUEST_SUCCESS: null,
	CHECK_CODE_REQUEST_ERROR: null,
	CLEAR_ERROR: null,
});
