import {HOSPITAL_ACTIONS} from './constants';

export const savePatient = patient => ({
  type: HOSPITAL_ACTIONS.SAVE_PATIENT,
  payload: {patient},
});

export const getJSON = () => ({
  type: HOSPITAL_ACTIONS.GET_ANSWERS_JSON,
});

export const getJSONSuccess = answersJSON => ({
  type: HOSPITAL_ACTIONS.GET_ANSWERS_JSON_SUCCESS,
  payload: {answersJSON},
});

export const getJSONError = error => ({
  type: HOSPITAL_ACTIONS.GET_ANSWERS_JSON_ERROR,
  payload: {error},
});
