import {INITIAL_STATE, HOSPITAL_ACTIONS} from './constants';
import {setPatient} from '../../services/userCRUD';

const hospitalReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  const {patient, answersJSON, error} = payload || {};

  switch(type) {
    case HOSPITAL_ACTIONS.SAVE_PATIENT:
      setPatient(patient);
      return {
        ...state,
        patient,
      };
    case HOSPITAL_ACTIONS.GET_ANSWERS_JSON_SUCCESS:
      return {
        ...state,
        answersJSON,
      };
    case HOSPITAL_ACTIONS.GET_ANSWERS_JSON_ERROR:
      return {
        ...state,
        error,
      };
    default:
      return state;
  };
}

export default hospitalReducer;
