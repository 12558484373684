import keymirror from 'keymirror';

export const INITIAL_STATE = {
  notification: null,
  ws: null,
  isPinModalOpened: false,
};

export const GLOBAL_ACTIONS = keymirror({
  TOGGLE_NOTIFICATION: null,
  WEBSOCKET_CONNECTION: null,
  TOGGLE_PIN_MODAL: null,
});
