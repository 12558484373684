import keymirror from 'keymirror';

export const INITIAL_STATE = {
  hospital_list: [],
  meta: {
    page: 1,
    per_page: 10,
  },
  error: null,
};

export const HOSPITAL_LIST_ACTIONS = keymirror({
  GET_HOSPITALS: null,
  GET_HOSPITALS_SUCCESS: null,
  GET_HOSPITALS_NEXT_PAGE_SUCCESS: null,
  GET_HOSPITALS_ERROR: null,
});
