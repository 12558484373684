import KeyMirror from "keymirror";

const FORGOT_PASSWORD_ACTIONS = KeyMirror({
  FORGOT_PASSWORD: null,
  FORGOT_PASSWORD_SUCCESS: null,
  FORGOT_PASSWORD_ERROR: null,
});

const INITIAL_STATE = {
  error: null,
};

export {FORGOT_PASSWORD_ACTIONS, INITIAL_STATE};
