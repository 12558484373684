import React, {Suspense, lazy, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Switch} from 'react-router';
import {ROUTES} from '../constants';
import PageWrapper from '../containers/PageWrapper';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import {isAuthenticated} from '../containers/SignIn/actions';

const Fallback = () => <div>Loading...</div>;
const Landing = lazy(() => import('../containers/Landing'));
const Registration = lazy(() => import('../containers/Registration'));
const Verification = lazy(() => import('../containers/Verification'));
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'));
const UserDashboard = lazy(() => import('../containers/UserDashboard'));
const Pin = lazy(() => import('../containers/PIN'));
const Login = lazy(() => import('../containers/SignIn'));
const HospitalAccessRequest = lazy(() => import('../containers/HospitalAccessRequest'));
const HospitalDashboard = lazy(() => import('../containers/HospitalDashboard'));
const HospitalList = lazy(() => import('../containers/HospitalList'));
const Categories = lazy(() => import('../containers/CategoriesList'));
const QR = lazy(() => import('../containers/QR'));
const Questionare = lazy(() => import('../containers/Questionare'));
const EditAnswers = lazy(() => import('../containers/EditAnswers'));

const Routes = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(isAuthenticated());
  }, [dispatch]);
  return (
    <Suspense fallback={<Fallback />}>
      <PageWrapper>
        <Switch>
          <PublicRoute path={ROUTES.HOME} component={Landing} exact />
          <PublicRoute path={ROUTES.LOGIN} component={Login} exact />
          <PublicRoute path={ROUTES.REGISTRATION} component={Registration} exact />
          <PublicRoute path={ROUTES.VERIFICATION} component={Verification} exact />
          <PublicRoute path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} exact />
          <PrivateRoute path={ROUTES.PIN} component={Pin} exact />
          <PrivateRoute path={ROUTES.USER_DASHBOARD} component={UserDashboard} exact />
          <PublicRoute path={ROUTES.ACCESS_REQUEST} component={HospitalAccessRequest} exact />
          <PrivateRoute path={ROUTES.HOSPITAL_DASHBOARD} component={HospitalDashboard} exact />
          <PrivateRoute path={ROUTES.HOSPITAL_LIST} component={HospitalList} exact />
          <PrivateRoute path={ROUTES.CATEGORIES} component={Categories} exact />
          <PrivateRoute path={ROUTES.SHARE_QR} component={QR} exact />
          <PrivateRoute path={ROUTES.CATEGORY_DETAILS} component={Questionare} exact />
          <PrivateRoute path={ROUTES.EDIT_ANSWERS} component={EditAnswers} exact />
        </Switch>
      </PageWrapper>
    </Suspense>
  );
}

export default Routes;
