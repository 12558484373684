import keymirror from 'keymirror';

export const INITIAL_STATE = {
	user: {},
	error: null,
};

export const PIN_ACTIONS = keymirror({
	CREATE_PIN_REQUEST: null,
	CREATE_PIN_REQUEST_SUCCESS: null,
	CREATE_PIN_REQUEST_ERROR: null,
	CHECK_PIN: null,
	CHECK_PIN_SUCCESS: null,
	CHECK_PIN_ERROR: null,
});