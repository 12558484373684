import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {ROUTES} from '../constants';
import {getToken, getConnectionToken} from '../services/tokenCRUD';
import {isUserValid} from '../services/Helpers';

function PrivateRoute({component, path, exact}) {
  const accessAllowed = getToken();
  const isConnected = getConnectionToken();
  const user = useSelector(({signInState}) => signInState.user);
  const isValid = isUserValid(user);

  if (!accessAllowed && !isConnected) return <Redirect to={ROUTES.LOGIN} />
  else if(isConnected && path !== ROUTES.HOSPITAL_DASHBOARD) return <Redirect to={ROUTES.HOSPITAL_DASHBOARD} />;
  else if (accessAllowed && !isValid && path !== ROUTES.PIN) return <Redirect to={ROUTES.PIN} />

  return (
    <Route exact={exact} path={path} component={component} />
  );

}

export default PrivateRoute;
