import {CATEGORIES_ACTIONS, INITIAL_STATE} from './constants';

const categoriesState = (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  const {meta, categories, total_pages, error} = payload || {};

  switch(type) {
    case CATEGORIES_ACTIONS.GET_CATEGORIES:
      return {
        ...state,
        meta,
      };
    case CATEGORIES_ACTIONS.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories,
        meta: {
          ...state.meta,
          total_pages,
        },
      };
    case CATEGORIES_ACTIONS.GET_CATEGORIES_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        categories: [...state.categories, ...categories],
        meta: {
          ...state.meta,
          total_pages,
        },
      };
    case CATEGORIES_ACTIONS.GET_CATEGORIES_ERROR:
      return {
        ...state,
        error,
      };
    default:
      return state;
  }
};

export default categoriesState;
