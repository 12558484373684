import React, {useState} from 'react';
import Modal from 'react-modal';
import PinInput from '../PIN/PinInput';
import './styles.css';

Modal.setAppElement('#root');

const PinModal = ({
  handleClosePinModal,
  checkPin,
  isPinModalOpened,
}) => {
  const [pin_code, setPincode] = useState('');
  const handleChangePin = newPin => {
    setPincode(newPin);
  };
  const handleSubmitPin = () => {
    checkPin(pin_code);
  };
  const handleKeyDown = () => {
    if (pin_code.length === 4)
      checkPin(pin_code);
  };
  return (
    <Modal
      isOpen={isPinModalOpened}
      onRequestClose={handleClosePinModal}
      contentLabel="Pin Modal"
      className="decline-modal-container"
      overlayClassName="decline-modal-overlay"
    >
      <h2 className="decline-modal-header">Enter your pin</h2>
      <PinInput
        pin_code={pin_code}
        handleChange={handleChangePin}
        onKeyDown={handleKeyDown}
      />
      <button className="decline-modal-button" onClick={handleSubmitPin}>OK</button>
      <button className="decline-modal-button pin-modal-cancel-btn" onClick={handleClosePinModal}>CANCEL</button>
    </Modal>
  );
};

export default PinModal;
