import {takeEvery, put, call} from 'redux-saga/effects';
import {goBack} from 'connected-react-router';
import {FORGOT_PASSWORD_ACTIONS} from './constants';
import {
	forgotPasswordSuccess,
	forgotPasswordError,
} from './actions';
import {processRequest} from '../../services/Api';
import {toggleNotification} from '../PageWrapper/actions';

function* handleForgotPassword(action) {
	try {
		const {payload} = action || {};
		const {email} = payload || {};
		const requestPayload = {email};
		const {data} = yield call(processRequest, '/passwords/forgot', 'POST', requestPayload);
		if (data.message) {
			// const user = data.user.data.attributes;
			yield put(forgotPasswordSuccess());
			yield put(toggleNotification({
				title: 'Success',
				message: `A new password has been sent to your email.`,
				type: 'success',
			}));
			yield put(goBack());
		} else if (data.error) {
			yield put(forgotPasswordError(data.error));
		} else {
			yield put(forgotPasswordError('Sorry! Something went wrong.'));
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (error_message) {
				message = error_message;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(forgotPasswordError(message));
      yield put(toggleNotification({title: 'Sorry!', message, type: 'danger'}));
		} else if (status === 401) {
			yield put(forgotPasswordError(error));
      yield put(toggleNotification({title: 'Sorry!', message: error, type: 'danger'}));
		} else if (status === 404) {
			yield put(forgotPasswordError(error_message || 'Something went wrong.'));
      yield put(toggleNotification({title: 'Sorry!', message: error_message || 'Something went wrong.', type: 'danger'}));
    } else if (status === 500) {
			yield put(
				forgotPasswordError(statusText || 'Internal server error.'),
			);
      yield put(toggleNotification({title: 'Sorry!', message: statusText || 'Internal server error.', type: 'danger'}));
		} else if (e.message) {
			yield put(forgotPasswordError(e.message));
      yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
		} else {
			yield put(forgotPasswordError('Internal server error.'));
      yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	}
}

export function* watchForgotPasswordSagas() {
	yield takeEvery(FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD, handleForgotPassword);
};