import keymirror from 'keymirror';

export const INITIAL_STATE = {
	user: null,
	error: null,
};

export const SIGN_IN_ACTIONS = keymirror({
  IS_AUTHENTICATED: null,
  AUTHENTICATED: null,
  NOT_AUTHENTICATED: null,
	LOGIN_REQUEST: null,
	LOGIN_REQUEST_SUCCESS: null,
	LOGIN_REQUEST_ERROR: null,
	LOGOUT_REQUEST: null,
	LOGOUT_REQUEST_SUCCESS: null,
	LOGOUT_REQUEST_ERROR: null,
});
