import {takeEvery, put, call} from 'redux-saga/effects';
import {push, goBack} from 'connected-react-router';
import {ROUTES} from '../../constants';
import {QUESTIONARE_ACTIONS} from './constants';
import {
	getQuestionsSuccess,
	getQuestionsError,
	getAnswersSuccess,
	getAnswersNextPageSuccess,
	getAnswersError,
	saveAnswerSuccess,
	saveAnswerError,
	editAnswerSuccess,
	editAnswerError,
	getScoreResultSuccess,
	getScoreResultError,
} from './actions';
import {toggleNotification} from '../PageWrapper/actions';
import {processRequest} from '../../services/Api';
import {getErrorMessage} from '../../services/SagaErrorHandler';

function* handleGetQuestions(action) {
  try {
    const {payload} = action || {};
    const {category_id} = payload || {};
		const {data} = yield call(processRequest, `/categories/${category_id}/first_question`, 'GET');
		if (data.questions.data) {
      // const questions = data.questions.data.map(question => question.attributes);
      const question = data.questions.data.attributes;
			yield put(getQuestionsSuccess(question));
      yield put(push(`${ROUTES.CATEGORY_DETAILS.replace(':id', '')}${category_id}`));
		} else if (!data.questions.data) {
			yield put(toggleNotification({title: 'Info', message: 'There no questions left on this category', type: 'info'}));
		} else if (data.error) {
			yield put(getQuestionsError(data.error));
			yield put(toggleNotification({title: 'Error', message: data.error, type: 'danger'}));
		} else {
			yield put(getQuestionsError('Sorry! Something went wrong.'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch (e) {
		const error_message = getErrorMessage(e);
		yield put(getQuestionsError(error_message));
		yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}))
	}
}

function* handleGetAnswers(action) {
  try {
    const {payload} = action || {};
		const {answers_meta} = payload || {};
    const {category_id, page} = answers_meta || {};
		const getAnswersSuccessfully = (page || 1) === 1 ? getAnswersSuccess : getAnswersNextPageSuccess;
		const {data} = yield call(processRequest, `/answers?page=${page || 1}&category_id=${category_id}`, 'GET');
		if (data.answer) {
      const answers = data.answer.data.map(answer => answer.attributes);
			yield put(getAnswersSuccessfully(answers, data.total_pages));
      yield put(push(ROUTES.EDIT_ANSWERS));
		} else if (data.error) {
			yield put(getAnswersError(data.error));
			yield put(toggleNotification({title: 'Error', message: data.error, type: 'danger'}));
		} else {
			yield put(getAnswersError('Sorry! Something went wrong.'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch (e) {
		const error_message = getErrorMessage(e);
		yield put(getAnswersError(error_message));
		yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}))
	}
}

function* handleSaveAnswer(action) {
	try {
		const {payload} = action || {};
		const {answer} = payload || {};
		const formData = new FormData();
		const keys = Object.keys(answer);
		keys.forEach(key => {
			if (key === 'document' && answer[key].document) {
				formData.append(`answer[${key}]`, answer[key].document);
			} else if (key === 'form_answer') {
				const json = JSON.stringify(answer[key]);
				formData.append(`answer[${key}]`, json);
			} else {
				formData.append(`answer[${key}]`, answer[key]);
			}
		});
		const {data} = yield call(processRequest, '/answers', 'POST', formData, false);
		if(data.question.data) {
			yield put(saveAnswerSuccess(data.question.data.attributes));
		} else {
      yield put(goBack());
		}
	} catch (e) {
		const error_message = getErrorMessage(e);
		yield put(saveAnswerError(error_message));
		yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}))
	}
}

function* handleEditAnswer(action) {
	try {
		const {payload} = action || {};
		const {answer} = payload || {};
		const formData = new FormData();
		const keys = Object.keys(answer);
		keys.forEach(key => {
			if (key === 'document' && answer[key].document) {
				formData.append(`answer[${key}]`, answer[key].document);
			} else if (key === 'form_answer') {
				const json = JSON.stringify(answer[key]);
				formData.append(`answer[${key}]`, json);
			} else {
				formData.append(`answer[${key}]`, answer[key]);
			}
		});
		const {data} = yield call(processRequest, `/answers/${answer.id}`, 'PUT', formData, false);
		if(data.answer.data) {
			yield put(editAnswerSuccess(data.answer.data.attributes));
			yield put(toggleNotification({title: 'Success', message: ' ', type: 'success'}))
		} else {
      yield put(goBack());
		}
	} catch(e) {
		const error_message = getErrorMessage(e);
		yield put(editAnswerError(error_message));
		yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}));
	}
}

function* handleGetScoreResult(action) {
	try {
		const {payload} = action || {};
		const {category_id} = payload || {};
		const {data} = yield call(processRequest, `/categories/${category_id}/score_result`, 'GET');
		if (data) {
			yield put(getScoreResultSuccess(data));
		}
	} catch(e) {
		const error_message = getErrorMessage(e);
		yield put(getScoreResultError(error_message));
		yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}));
	}
}

export function* watchQuestionareSagas() {
	yield takeEvery(QUESTIONARE_ACTIONS.GET_QUESTIONS, handleGetQuestions);
	yield takeEvery(QUESTIONARE_ACTIONS.GET_ANSWERS, handleGetAnswers);
	yield takeEvery(QUESTIONARE_ACTIONS.SAVE_ANSWER, handleSaveAnswer);
	yield takeEvery(QUESTIONARE_ACTIONS.EDIT_ANSWER, handleEditAnswer);
	yield takeEvery(QUESTIONARE_ACTIONS.GET_SCORE_RESULT, handleGetScoreResult);
};
