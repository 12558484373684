import {ACCESS_REQUEST_ACTIONS} from './constants';

export const accessRequest = requestData => ({
  type: ACCESS_REQUEST_ACTIONS.ACCESS_REQUEST,
  payload: {requestData},
});

export const accessRequestSuccess = token => ({
  type: ACCESS_REQUEST_ACTIONS.ACCESS_REQUEST_SUCCESS,
  payload: {token},
});

export const accessRequestError = error => ({
  type: ACCESS_REQUEST_ACTIONS.ACCESS_REQUEST_ERROR,
  payload: {error},
});
