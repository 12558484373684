const Routes = {
  HOME: '/',
  REGISTRATION: '/registration',
  VERIFICATION: '/verification',
  FORGOT_PASSWORD: '/forgot-password',
  PIN: '/pin',
  ANSWER: '/answer',
  USER_DASHBOARD: '/user-dashboard',
  LOGIN: '/login',
  ACCESS_REQUEST: '/access-request',
  HOSPITAL_DASHBOARD: '/hospital-dashboard',
  CATEGORIES: '/categories',
  HOSPITAL_LIST: '/hospital-list',
  SHARE_QR: '/share-qr',
  CATEGORY_DETAILS: '/category/:id',
  EDIT_ANSWERS: '/edit-answers',
};

export default Routes;
