import {GLOBAL_ACTIONS} from './constants';

export const toggleNotification = notification => ({
  type: GLOBAL_ACTIONS.TOGGLE_NOTIFICATION,
  payload: {notification},
});

export const websocketConnection = ws => ({
  type: GLOBAL_ACTIONS.WEBSOCKET_CONNECTION,
  payload: {ws},
});

export const togglePinModal = isPinModalOpened => ({
  type: GLOBAL_ACTIONS.TOGGLE_PIN_MODAL,
  payload: {isPinModalOpened},
});
