import SecureLS from 'secure-ls';
import {LOCAL_STORAGE} from '../constants';

export const secureLS = new SecureLS();

export const getToken = () => secureLS.get(LOCAL_STORAGE.AUTH_TOKEN);

export const setToken = (token) => {
  secureLS.set(LOCAL_STORAGE.AUTH_TOKEN, token);
}

export const removeToken = () => {
  secureLS.remove(LOCAL_STORAGE.AUTH_TOKEN);
}

export const getConnectionToken = () => secureLS.get(LOCAL_STORAGE.TOKEN);

export const setConnectionToken = (token) => {
  secureLS.set(LOCAL_STORAGE.TOKEN, token);
}

export const removeConnectionToken = () => {
  secureLS.remove(LOCAL_STORAGE.TOKEN);
}
