import React, {useEffect, useState} from 'react';
import {replace} from 'connected-react-router';
import {useDispatch, useSelector} from 'react-redux';
import actioncable from 'actioncable';
import Modal from 'react-modal';
import ReactNotification, {store} from 'react-notifications-component';
import classnames from 'classnames';
import {config} from '../../config';
import {
  toggleNotification,
  websocketConnection,
  togglePinModal,
} from './actions';
import {logoutRequest} from '../SignIn/actions';
import {checkPin} from '../PIN/actions';
import {ROUTES} from '../../constants';
import {savePatient} from '../HospitalDashboard/actions';
import {accessRequestSuccess} from '../HospitalAccessRequest/actions';
import {removeConnectionToken} from '../../services/tokenCRUD';
import {removePatient} from '../../services/userCRUD';
import {setLogoutTimeout} from '../../services/Helpers';
import PinModal from './PinModal';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.compat.css';
import './styles.css';

Modal.setAppElement('#root');

const PageWrapper = ({
  children,
}) => {
  const [isModalOpened, toggleModal] = useState(false);
  const dispatch = useDispatch();
  const {notification, ws, isPinModalOpened} = useSelector(({globalState}) => globalState);
  const token = useSelector(({accessRequestState}) => accessRequestState.token);
  const {patient} = useSelector(({hospitalState}) => hospitalState);
	const user = useSelector(({signInState}) => signInState.user);
	const color = user?.hospital?.data && user?.hospital?.data.attributes.primary_color ? user.hospital.data.attributes.primary_color : '#009a49';
  let channel = null;
  const handleCloseNotification = () => {
    dispatch(toggleNotification(null));
  };
  useEffect(() => {
    const html = document.getElementsByTagName('html')[0];
    html.style = `--main-color: ${color === 'dark' ? '#000' : color}`;
  }, [user]);
  useEffect(() => {
    if (token && !ws) {
      const socket = actioncable.createConsumer(config.REACT_APP_WS_URL);
      dispatch(websocketConnection(socket));
    }
  }, [token]);
  useEffect(() => {
    if (ws && token) {
      channel = ws.subscriptions.create(
        {
          channel: `HospitalChannel`,
          token,
        },
        {
          connected: connected,
          disconnected: disconnected,
          received: received,
        },
      );
    }
  }, [ws, token]);
  useEffect(() => {
    if(notification) {
      store.addNotification({
        title: notification.title || '',
        message: notification.message || '',
        type: notification.type || 'success',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animated', 'fadeIn'],
        animationOut: ['animated', 'fadeOut'],
        dismiss: {
          duration: 5000,
          onScreen: true
        },
        onRemoval: handleCloseNotification
      });
    }
  }, [notification]);
  useEffect(() => {
    if (patient)
      setLogoutTimeout(clearConnection);
  }, [patient]);
  const clearConnection = () => {
    dispatch(accessRequestSuccess(null));
    removeConnectionToken();
    removePatient();
    dispatch(replace(ROUTES.HOME));
  };
  const connected = () => {
    console.log('connected');
  };
  const disconnected = () => {
    dispatch(websocketConnection(null));
  };
  const received = data => {
    const {user, message} = data || {};
    if (ws) {
      ws.disconnect();
    }
    if (user) {
      dispatch(savePatient(user));
      dispatch(replace(ROUTES.HOSPITAL_DASHBOARD));
      setLogoutTimeout(clearConnection);
      dispatch(toggleNotification({
        title: 'Success',
        message: 'Patient has approve access',
        type: 'success',
      }));
    } else {
      toggleModal(true);
    }
  };
  const handleClosePinModal = () => {
    dispatch(togglePinModal(false));
    dispatch(logoutRequest());
  };
  const closeModal = () => {
    toggleModal(false);
    dispatch(replace(ROUTES.HOME));
    dispatch(accessRequestSuccess(null));
    removeConnectionToken();
    removePatient();
  };
  const handleSubmitPin = pin_code => {
    dispatch(checkPin(pin_code));
  };
  return (
    <div className="page_container">
      <div
        className={
          classnames("page_wrapper")
        }
        id="scrollableWrapper"
      >
        <ReactNotification />
        {children}
        <Modal
          isOpen={isModalOpened}
          onRequestClose={closeModal}
          contentLabel="Decline Modal"
          className="decline-modal-container"
          overlayClassName="decline-modal-overlay"
        >
          <h2 className="decline-modal-header">Patient has decline your request</h2>
          <button className="decline-modal-button" onClick={closeModal}>OK</button>
        </Modal>
        <PinModal
          isPinModalOpened={isPinModalOpened}
          handleClosePinModal={handleClosePinModal}
          checkPin={handleSubmitPin}
        />
      </div>
    </div>
  );
};

export default PageWrapper;
