import {takeEvery, put, call} from 'redux-saga/effects';
// import {push} from 'connected-react-router';
import {SIGN_IN_ACTIONS} from './constants';
// import {ROUTES} from '../../constants';
import {
	authenticated,
	notAuthenticated,
	loginSuccess,
	loginError,
	logoutRequestSuccess,
	logoutRequestError,
} from './actions';
import {togglePinModal} from '../PageWrapper/actions';
import {accessRequestSuccess} from '../HospitalAccessRequest/actions';
import {savePatient} from '../HospitalDashboard/actions';
import {processRequest} from '../../services/Api';
import {setToken, removeToken, getConnectionToken} from '../../services/tokenCRUD';
import {getUser, setUser, removeUser, getPatient} from '../../services/userCRUD';

function* handleIsAuthenticated() {
	try {
    const user = yield call(getUser);
		const tok = yield call(getConnectionToken);
		const patient = yield call(getPatient);
		if (user) {
			yield put(authenticated(user));
			yield put(togglePinModal(true));
		} else {
			// yield put(toggleNotification({title: 'Hello', message: 'Message', type: 'success'}))
			// yield put(toggleNotification({title: 'Hello', message: 'Message', type: 'danger'}))
			yield put(notAuthenticated(''));
		}
		if (tok) {
			yield put(accessRequestSuccess(tok));
		}
		if (patient) {
			yield put(savePatient(patient));
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (error_message) {
				message = error_message;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(notAuthenticated(message));
		} else if (status === 401) {
			yield put(notAuthenticated(error));
		} else if (status === 500) {
			yield put(
				notAuthenticated(statusText || 'Internal server error.'),
			);
		} else if (e.message) {
			yield put(notAuthenticated(e.message));
		} else {
			yield put(notAuthenticated('Internal server error.'));
		}
	}
}

function* handleLogin(action) {
	try {
		const {payload} = action || {};
		const {userData} = payload || {};
		const {email, password} = userData || {};
		const requestPayload = {
			user: {
					email,
					password,
			},
		};
		const {data} = yield call(processRequest, '/authorization/login', 'POST', requestPayload);
		if (data.user) {
			const user = data.user.data.attributes;
			setToken(data.token.auth_token);
			setUser(user);
			yield put(loginSuccess(user));
			// yield put(push(ROUTES.USER_DASHBOARD));
			yield put(togglePinModal(true));
		} else if (data.error) {
			yield put(loginError(data.error));
		} else {
			yield put(loginError('Sorry! Something went wrong.'));
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (error_message) {
				message = error_message;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(loginError(message));
		} else if (status === 401) {
			yield put(loginError(error));
		} else if (status === 500) {
			yield put(
				loginError(statusText || 'Internal server error.'),
			);
		} else if (e.message) {
			yield put(loginError(e.message));
		} else {
			yield put(loginError('Internal server error.'));
		}
	}
}

function* handleLogout() {
	try {
		removeToken();
		removeUser();
		yield put(logoutRequestSuccess());
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (error_message) {
				message = error_message;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(logoutRequestError(message));
		} else if (status === 401) {
			yield put(logoutRequestError(error));
		} else if (status === 500) {
			yield put(
				logoutRequestError(statusText || 'Internal server error.'),
			);
		} else if (e.message) {
			yield put(logoutRequestError(e.message));
		} else {
			yield put(logoutRequestError('Internal server error.'));
		}
	}
}

export function* watchSignInSagas() {
	yield takeEvery(SIGN_IN_ACTIONS.IS_AUTHENTICATED, handleIsAuthenticated);
	yield takeEvery(SIGN_IN_ACTIONS.LOGIN_REQUEST, handleLogin);
	yield takeEvery(SIGN_IN_ACTIONS.LOGOUT_REQUEST, handleLogout);
};