import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import registrationState from "../containers/Registration/reducer";
import pinState from "../containers/PIN/reducer"
import signInState from '../containers/SignIn/reducer';
import accessRequestState from '../containers/HospitalAccessRequest/reducer';
import globalState from '../containers/PageWrapper/reducer';
import hospitalState from '../containers/HospitalDashboard/reducer';
import hospitalListState from '../containers/HospitalList/reducer';
import categoriesState from '../containers/CategoriesList/reducer';
import userDashboardState from '../containers/UserDashboard/reducer';
import questionareState from '../containers/Questionare/reducer';
import forgotPasswordReducer from '../containers/ForgotPassword/reducer';

const reducers = history => combineReducers({
  router: connectRouter(history),
  registrationState,
  pinState,
  signInState,
  accessRequestState,
  globalState,
  hospitalState,
  hospitalListState,
  categoriesState,
  userDashboardState,
  questionareState,
  forgotPasswordReducer,
});

export default reducers;
