import keymirror from 'keymirror';

export const INITIAL_STATE = {
  categories: [],
  meta: {
    page: 1,
    per_page: 10,
    total_pages: 1,
    category_type: 'pre_op',
  },
  error: null,
};

export const CATEGORIES_ACTIONS = keymirror({
  GET_CATEGORIES: null,
  GET_CATEGORIES_SUCCESS: null,
  GET_CATEGORIES_NEXT_PAGE_SUCCESS: null,
  GET_CATEGORIES_ERROR: null,
});
