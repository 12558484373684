import keymirror from 'keymirror';

export const INITIAL_STATE = {
  error: null,
  patient: null,
  answersJSON: null,
};

export const HOSPITAL_ACTIONS = keymirror({
  SAVE_PATIENT: null,
  GET_ANSWERS_JSON: null,
  GET_ANSWERS_JSON_SUCCESS: null,
  GET_ANSWERS_JSON_ERROR: null,
});
