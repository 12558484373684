import {CATEGORIES_ACTIONS} from './constants';

export const getCategories = meta => ({
  type: CATEGORIES_ACTIONS.GET_CATEGORIES,
  payload: {meta},
});

export const getCategoriesSuccess = (categories, total_pages) => ({
  type: CATEGORIES_ACTIONS.GET_CATEGORIES_SUCCESS,
  payload: {categories, total_pages},
});

export const getCategoriesNextPageSuccess = (categories, total_pages) => ({
  type: CATEGORIES_ACTIONS.GET_CATEGORIES_NEXT_PAGE_SUCCESS,
  payload: {categories, total_pages},
});

export const getCategoriesError = error => ({
  type: CATEGORIES_ACTIONS.GET_CATEGORIES_ERROR,
  payload: {error},
});
