import {REGISTRATION_ACTIONS, INITIAL_STATE} from "./constants";
import {SIGN_IN_ACTIONS} from '../SignIn/constants';

const registrationReducer = (state = INITIAL_STATE, action) => {
	const {type, payload} = action || {};
	const {userData, user, error} = payload || {};

	switch (type){
		case REGISTRATION_ACTIONS.REGISTRATION_REQUEST_ERROR:
		case REGISTRATION_ACTIONS.PHONE_VERIFICATION_REQUEST_ERROR:
		case REGISTRATION_ACTIONS.CHECK_CODE_REQUEST_ERROR:
			return {
				...state,
				error,
			};
		case REGISTRATION_ACTIONS.PHONE_VERIFICATION_REQUEST:
			return {
				...state,
				userData,
			};
    case SIGN_IN_ACTIONS.AUTHENTICATED:
			return {
				...state,
				userData: user,
			};
		case REGISTRATION_ACTIONS.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}

export default registrationReducer;
