import {HOSPITAL_LIST_ACTIONS, INITIAL_STATE} from './constants';
import {USER_ACTIONS} from '../UserDashboard/constants';

const hospitalListState = (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  const {meta, hospital_list, access_request, error} = payload || {};

  switch(type) {
    case HOSPITAL_LIST_ACTIONS.GET_HOSPITALS:
      return {
        ...state,
        meta,
      };
    case HOSPITAL_LIST_ACTIONS.GET_HOSPITALS_SUCCESS:
      return {
        ...state,
        hospital_list,
      };
    case HOSPITAL_LIST_ACTIONS.GET_HOSPITALS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        hospital_list: [...state.hospital_list, ...hospital_list],
      };
    case HOSPITAL_LIST_ACTIONS.GET_HOSPITALS_ERROR:
      return {
        ...state,
        error,
      };
    case USER_ACTIONS.UPDATE_REQUEST_STATE_SUCCESS: {
      const hospital_list = [...state.hospital_list];
      hospital_list.forEach((hospital, index) => {
        if (hospital.id === access_request.id) {
          hospital_list[index] = {
            ...hospital_list[index],
            ...access_request,
          };
        }
      });
      return {
        ...state,
        hospital_list,
      };
    }
    default:
      return state;
  }
};

export default hospitalListState;
