import {REGISTRATION_ACTIONS} from "./constants";

export const registrationRequest = userData => ({
	type: REGISTRATION_ACTIONS.REGISTRATION_REQUEST,
	payload: {userData},
});

export const registrationSuccess = user => ({
	type: REGISTRATION_ACTIONS.REGISTRATION_REQUEST_SUCCESS,
	payload: {user},
});

export const registrationError = error => ({
	type: REGISTRATION_ACTIONS.REGISTRATION_REQUEST_ERROR,
	payload: {error},
});

export const phoneVerificationRequest = userData => ({
	type: REGISTRATION_ACTIONS.PHONE_VERIFICATION_REQUEST,
	payload: {userData},
});

export const phoneVerificationRequestSuccess = () => ({
	type: REGISTRATION_ACTIONS.PHONE_VERIFICATION_REQUEST_SUCCESS,
});

export const phoneVerificationRequestError = error => ({
	type: REGISTRATION_ACTIONS.PHONE_VERIFICATION_REQUEST_ERROR,
	payload: {error},
});

export const checkCodeRequest = userData => ({
	type: REGISTRATION_ACTIONS.CHECK_CODE_REQUEST,
	payload: {userData},
});

export const checkCodeRequestSuccess = () => ({
	type: REGISTRATION_ACTIONS.CHECK_CODE_REQUEST_SUCCESS,
});

export const checkCodeRequestError = error => ({
	type: REGISTRATION_ACTIONS.CHECK_CODE_REQUEST_ERROR,
	payload: {error},
});

export const clearError = () => ({
	type: REGISTRATION_ACTIONS.CLEAR_ERROR,
});
