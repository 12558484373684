import {HOSPITAL_LIST_ACTIONS} from './constants';

export const getHospitals = meta => ({
  type: HOSPITAL_LIST_ACTIONS.GET_HOSPITALS,
  payload: {meta},
});

export const getHospitalsSuccess = hospital_list => ({
  type: HOSPITAL_LIST_ACTIONS.GET_HOSPITALS_SUCCESS,
  payload: {hospital_list},
});

export const getHospitalsNextPageSuccess = hospital_list => ({
  type: HOSPITAL_LIST_ACTIONS.GET_HOSPITALS_NEXT_PAGE_SUCCESS,
  payload: {hospital_list},
});

export const getHospitalsError = error => ({
  type: HOSPITAL_LIST_ACTIONS.GET_HOSPITALS_ERROR,
  payload: {error},
});
