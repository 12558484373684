import {INITIAL_STATE, QUESTIONARE_ACTIONS} from './constants';

const questionareState = (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  const {
    questionare,
    category,
    answers_meta,
    answers,
    total_pages,
    answer,
    score_result,
    error,
  } = payload || {};
  switch(type) {
    case QUESTIONARE_ACTIONS.GET_QUESTIONS:
      return {
        ...state,
        category,
      };
    case QUESTIONARE_ACTIONS.GET_QUESTIONS_SUCCESS:
      return {
        ...state,
        questionare,
      };
    case QUESTIONARE_ACTIONS.GET_ANSWERS:
      return {
        ...state,
        answers_meta,
        category,
      };
    case QUESTIONARE_ACTIONS.GET_ANSWERS_SUCCESS:
      return {
        ...state,
        answers,
        answers_meta: {
          ...state.answers_meta,
          total_pages,
        },
      };
    case QUESTIONARE_ACTIONS.GET_ANSWERS_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        answers: [...state.answers, ...answers],
        answers_meta: {
          ...state.answers_meta,
          total_pages,
        },
      };
    case QUESTIONARE_ACTIONS.SAVE_ANSWER_SUCCESS:
      return {
        ...state,
        questionare,
      };
    case QUESTIONARE_ACTIONS.EDIT_ANSWER_SUCCESS: {
      const newAnswers = [...state.answers];
      const editedAnswerIndex = newAnswers.map(oldAnswer => oldAnswer.id).indexOf(answer.id);
      if (editedAnswerIndex >= 0) {
        newAnswers[editedAnswerIndex] = {
          ...newAnswers[editedAnswerIndex],
          ...answer,
        };
      }
      return {
        ...state,
        answers: newAnswers,
      };
    }
    case QUESTIONARE_ACTIONS.GET_SCORE_RESULT_SUCCESS:
      return {
        ...state,
        score_result,
      };
    case QUESTIONARE_ACTIONS.CLEAR_SCORE_RESULT:
      return {
        ...state,
        score_result: null,
      };
    case QUESTIONARE_ACTIONS.GET_QUESTIONS_ERROR:
    case QUESTIONARE_ACTIONS.GET_ANSWERS_ERROR:
    case QUESTIONARE_ACTIONS.SAVE_ANSWER_ERROR:
    case QUESTIONARE_ACTIONS.EDIT_ANSWER_ERROR:
    case QUESTIONARE_ACTIONS.GET_SCORE_RESULT_ERROR:
      return {
        ...state,
        error,
      };
    default:
      return state;
  }
};

export default questionareState;
