import {LOCAL_STORAGE} from '../constants';
import {secureLS} from './tokenCRUD';

export const getUser = () => {
  const userJSON = secureLS.get(LOCAL_STORAGE.USER);
  if (userJSON)
    return JSON.parse(userJSON);
  else
    return null;
}

export const setUser = user => {
  const userJSON = JSON.stringify(user);
  secureLS.set(LOCAL_STORAGE.USER, userJSON);
}

export const removeUser = () => {
  secureLS.remove(LOCAL_STORAGE.USER);
}

export const getPatient = () => {
  const userJSON = secureLS.get(LOCAL_STORAGE.PATIENT);
  if (userJSON)
    return JSON.parse(userJSON);
  else
    return null;
}

export const setPatient = user => {
  const userJSON = JSON.stringify(user);
  secureLS.set(LOCAL_STORAGE.PATIENT, userJSON);
}

export const removePatient = () => {
  secureLS.remove(LOCAL_STORAGE.PATIENT);
}
