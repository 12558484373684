import {fork} from 'redux-saga/effects';
import * as registrationSagas from '../containers/Registration/sagas';
import * as pinSagas from '../containers/PIN/sagas';
import * as signInSagas from '../containers/SignIn/sagas';
import * as accessRequestSagas from '../containers/HospitalAccessRequest/sagas';
import * as hospitalSagas from '../containers/HospitalDashboard/sagas';
import * as hospitalListSagas from '../containers/HospitalList/sagas';
import * as categoriesSagas from '../containers/CategoriesList/sagas';
import * as userSagas from '../containers/UserDashboard/sagas';
import * as questionsSagas from '../containers/Questionare/sagas';
import * as forgotPasswordSagas from '../containers/ForgotPassword/sagas';

export default function* rootSaga(store) {
  yield fork(registrationSagas.watchRegistrationSagas, store);
  yield fork(pinSagas.watchPinSagas, store);
  yield fork(signInSagas.watchSignInSagas, store);
  yield fork(accessRequestSagas.watchAccessRequestSagas, store);
  yield fork(hospitalSagas.watchHospitalDashboardSagas, store);
  yield fork(hospitalListSagas.watchHospitalListSagas, store);
  yield fork(categoriesSagas.watchCategoriesSagas, store);
  yield fork(userSagas.watchUserSagas, store);
  yield fork(questionsSagas.watchQuestionareSagas, store);
  yield fork(forgotPasswordSagas.watchForgotPasswordSagas, store);
}
