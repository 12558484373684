import {takeEvery, put, call} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {ROUTES} from '../../constants';
import {CATEGORIES_ACTIONS} from './constants';
import {getCategoriesSuccess, getCategoriesNextPageSuccess, getCategoriesError} from './actions';
import {toggleNotification} from '../PageWrapper/actions';
import {processRequest} from '../../services/Api';

function* handleGetCategories(action) {
  try {
    const {payload} = action || {};
    const {meta} = payload || {};
    const {page, per_page, category_type} = meta || {};
    const getCategoriesListSuccess = page === 1 ? getCategoriesSuccess : getCategoriesNextPageSuccess;
		const {data} = yield call(processRequest, `/categories?page=${page}&per_page=${per_page}&category_type=${category_type}`, 'GET');
		if (data.categories) {
			// yield put(push(ROUTES.USER_DASHBOARD));
      const categories = data.categories.data.map(category => category.attributes);
			yield put(getCategoriesListSuccess(categories, data.total_pages));
		} else if (data.error) {
			yield put(getCategoriesError(data.error));
			yield put(toggleNotification({title: 'Error', message: data.error, type: 'danger'}));
		} else {
			yield put(getCategoriesError('Sorry! Something went wrong.'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (typeof error_message === 'string') {
				message = error_message;
			} else if (error_message) {
				const keys = Object.keys(error_message);
				const errorMessage = error_message[keys[0]][0];
				message = `${keys[0]} ${errorMessage}`;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(getCategoriesError(message));
			yield put(toggleNotification({title: 'Sorry!', message: message || 'Something went wrong.', type: 'danger'}));
		} else if (status === 401) {
			yield put(getCategoriesError(error));
			yield put(push(ROUTES.HOME));
		} else if (status === 404) {
      if (error_message) {
				yield put(getCategoriesError(error_message));
				yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}));
			} else {
				yield put(getCategoriesError(e.message));
				yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
			}
    } else if (status === 500) {
			yield put(
				getCategoriesError(statusText || 'Internal server error.'),
			);
			yield put(toggleNotification({title: 'Sorry!', message: statusText || 'Internal server error.', type: 'danger'}));
		} else if (e.message) {
			yield put(getCategoriesError(e.message));
			yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
		} else {
			yield put(getCategoriesError('Network error'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Network error', type: 'danger'}));
		}
	}
}

export function* watchCategoriesSagas() {
	yield takeEvery(CATEGORIES_ACTIONS.GET_CATEGORIES, handleGetCategories);
};
