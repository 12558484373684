import {takeEvery, put, call} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {ROUTES} from '../../constants';
import {HOSPITAL_ACTIONS} from './constants';
import {getJSONSuccess, getJSONError, savePatient} from './actions';
import {accessRequestSuccess} from '../HospitalAccessRequest/actions';
import {toggleNotification} from '../PageWrapper/actions';
import {processRequest} from '../../services/Api';
import {getConnectionToken, removeConnectionToken} from '../../services/tokenCRUD';
import {removePatient} from '../../services/userCRUD';

function* handleGetJSON() {
  try {
    const token = yield call(getConnectionToken);
		const {data} = yield call(processRequest, `/questionnaires?token=${token}`, 'GET');
		if (data.questionnaire) {
			// yield put(push(ROUTES.USER_DASHBOARD));
			yield put(getJSONSuccess(data.questionnaire));
		} else if (data.error) {
			yield put(getJSONError(data.error));
			yield put(toggleNotification({title: 'Error', message: data.error, type: 'danger'}));
		} else {
			yield put(getJSONError('Sorry! Something went wrong.'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (typeof error_message === 'string') {
				message = error_message;
			} else if (error_message) {
				const keys = Object.keys(error_message);
				const errorMessage = error_message[keys[0]][0];
				message = `${keys[0]} ${errorMessage}`;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(getJSONError(message));
			yield put(toggleNotification({title: 'Sorry!', message: message || 'Something went wrong.', type: 'danger'}));
		} else if (status === 401) {
			yield put(getJSONError(error));
			yield put(removePatient());
			yield put(removeConnectionToken());
			yield put(accessRequestSuccess(null));
			yield put(savePatient(null));
			yield put(push(ROUTES.HOME));
		} else if (status === 404) {
      if (error_message) {
				yield put(getJSONError(error_message));
				yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}));
			} else {
				yield put(getJSONError(e.message));
				yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
			}
    } else if (status === 500) {
			yield put(
				getJSONError(statusText || 'Internal server error.'),
			);
			yield put(toggleNotification({title: 'Sorry!', message: statusText || 'Internal server error.', type: 'danger'}));
		} else if (e.message) {
			yield put(getJSONError(e.message));
			yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
		} else {
			yield put(getJSONError('Network error'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Network error', type: 'danger'}));
		}
	}
}

export function* watchHospitalDashboardSagas() {
	yield takeEvery(HOSPITAL_ACTIONS.GET_ANSWERS_JSON, handleGetJSON);
};
