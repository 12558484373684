import {ACCESS_REQUEST_ACTIONS, INITIAL_STATE} from './constants';
import {REGISTRATION_ACTIONS} from '../Registration/constants';

const accessRequestReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  const {token, error} = payload || {};

  switch(type) {
    case ACCESS_REQUEST_ACTIONS.ACCESS_REQUEST_SUCCESS:
      return {
        ...state,
        token,
      };
    case ACCESS_REQUEST_ACTIONS.ACCESS_REQUEST_ERROR:
      return {
        ...state,
        error,
      };
    case REGISTRATION_ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export default accessRequestReducer;
