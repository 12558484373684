import {takeEvery, put, call} from 'redux-saga/effects';
import actioncable from 'actioncable';
import {ACCESS_REQUEST_ACTIONS} from './constants';
import {accessRequestSuccess, accessRequestError} from './actions';
import {toggleNotification, websocketConnection} from '../PageWrapper/actions';
import {processRequest} from '../../services/Api';
import {setConnectionToken} from '../../services/tokenCRUD';
import {config} from '../../config';

function* handleAccessRequest(action) {
  try {
		const {payload} = action || {};
		const {requestData} = payload || {};
    const {token, hospital_name, doctor_name, imc_number} = requestData || {};
		const requestPayload = {
			access_request: {token, hospital_name, doctor_name, imc_number},
		};
		const {data} = yield call(processRequest, `/access_requests`, 'POST', requestPayload);
		if (data) {
			// yield put(push(ROUTES.USER_DASHBOARD));
      const socket = actioncable.createConsumer(config.REACT_APP_WS_URL);
      yield put(websocketConnection(socket));
			yield call(setConnectionToken, token);
			yield put(accessRequestSuccess(token));
			yield put(toggleNotification({title: 'Success', message: 'Please wait for patient approve', type: 'success'}));
		} else if (data.error) {
			yield put(accessRequestError(data.error));
			yield put(toggleNotification({title: 'Error', message: data.error, type: 'danger'}));
		} else {
			yield put(accessRequestError('Sorry! Something went wrong.'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (typeof error_message === 'string') {
				message = error_message;
			} else if (error_message) {
				const keys = Object.keys(error_message);
				const errorMessage = error_message[keys[0]][0];
				message = `${keys[0]} ${errorMessage}`;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(accessRequestError(message));
			yield put(toggleNotification({title: 'Sorry!', message, type: 'danger'}));
		} else if (status === 401) {
			yield put(accessRequestError(error));
		} else if (status === 404) {
      if (error_message) {
				yield put(accessRequestError(error_message));
				yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}));
			} else {
				yield put(accessRequestError(e.message));
				yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
			}
    } else if (status === 500) {
			yield put(
				accessRequestError(statusText || 'Internal server error.'),
			);
			yield put(toggleNotification({title: 'Sorry!', message: statusText || 'Internal server error.', type: 'danger'}));
		} else if (e.message) {
			yield put(accessRequestError(e.message));
			yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
		} else {
			yield put(accessRequestError('Network error'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Network error', type: 'danger'}));
		}
	}
}

export function* watchAccessRequestSagas() {
	yield takeEvery(ACCESS_REQUEST_ACTIONS.ACCESS_REQUEST, handleAccessRequest);
};
