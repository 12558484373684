import React from 'react';
import {useSelector} from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import {ROUTES} from '../constants';
import {getToken, getConnectionToken} from '../services/tokenCRUD';
import {isUserValid} from '../services/Helpers';

const PublicRoute = ({component, path, exact}) => {
  const accessAllowed = getToken();
  const isConnected = getConnectionToken();
  const user = useSelector(({signInState}) => signInState.user);
  const isValid = isUserValid(user);

  if (accessAllowed && !isValid) return <Redirect to={ROUTES.PIN} />;
  else if (accessAllowed) return <Redirect to={ROUTES.USER_DASHBOARD} />;
  else if(isConnected) return <Redirect to={ROUTES.HOSPITAL_DASHBOARD} />;

  return (
    <Route exact={exact} path={path} component={component} />
  );
}

export default PublicRoute;
