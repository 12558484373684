import {QUESTIONARE_ACTIONS} from './constants';

export const getQuestions = (category_id, category) => ({
  type: QUESTIONARE_ACTIONS.GET_QUESTIONS,
  payload: {category_id, category},
});

export const getQuestionsSuccess = questionare => ({
  type: QUESTIONARE_ACTIONS.GET_QUESTIONS_SUCCESS,
  payload: {questionare},
});

export const getQuestionsError = error => ({
  type: QUESTIONARE_ACTIONS.GET_QUESTIONS_ERROR,
  payload: {error},
});

export const getAnswers = (answers_meta, category) => ({
  type: QUESTIONARE_ACTIONS.GET_ANSWERS,
  payload: {answers_meta, category},
});

export const getAnswersSuccess = (answers, total_pages) => ({
  type: QUESTIONARE_ACTIONS.GET_ANSWERS_SUCCESS,
  payload: {answers, total_pages},
});

export const getAnswersNextPageSuccess = (answers, total_pages) => ({
  type: QUESTIONARE_ACTIONS.GET_ANSWERS_NEXT_PAGE_SUCCESS,
  payload: {answers, total_pages},
});

export const getAnswersError = error => ({
  type: QUESTIONARE_ACTIONS.GET_ANSWERS_ERROR,
  payload: {error},
});

export const saveAnswer = answer => ({
  type: QUESTIONARE_ACTIONS.SAVE_ANSWER,
  payload: {answer},
});

export const saveAnswerSuccess = questionare => ({
  type: QUESTIONARE_ACTIONS.SAVE_ANSWER_SUCCESS,
  payload: {questionare},
});

export const saveAnswerError = error => ({
  type: QUESTIONARE_ACTIONS.SAVE_ANSWER_ERROR,
  payload: {error},
});

export const editAnswer = answer => ({
  type: QUESTIONARE_ACTIONS.EDIT_ANSWER,
  payload: {answer},
});

export const editAnswerSuccess = answer => ({
  type: QUESTIONARE_ACTIONS.EDIT_ANSWER_SUCCESS,
  payload: {answer},
});

export const editAnswerError = error => ({
  type: QUESTIONARE_ACTIONS.EDIT_ANSWER_ERROR,
  payload: {error},
});

export const getScoreResult = category_id => ({
  type: QUESTIONARE_ACTIONS.GET_SCORE_RESULT,
  payload: {category_id},
});

export const getScoreResultSuccess = score_result => ({
  type: QUESTIONARE_ACTIONS.GET_SCORE_RESULT_SUCCESS,
  payload: {score_result},
});

export const getScoreResultError = error => ({
  type: QUESTIONARE_ACTIONS.GET_SCORE_RESULT_ERROR,
  payload: {error},
});

export const clearScoreResult = () => ({
  type: QUESTIONARE_ACTIONS.CLEAR_SCORE_RESULT,
});
