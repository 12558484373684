import {PIN_ACTIONS} from "./constants";

export const createPinRequest = pin_code => ({
	type: PIN_ACTIONS.CREATE_PIN_REQUEST,
	payload: {pin_code},
});

export const createPinSuccess = user => ({
	type: PIN_ACTIONS.CREATE_PIN_REQUEST_SUCCESS,
	payload: {user},
});

export const createPinError = error => ({
	type: PIN_ACTIONS.CREATE_PIN_REQUEST_ERROR,
	payload: {error},
});

export const checkPin = (pin_code, answer) => ({
	type: PIN_ACTIONS.CHECK_PIN,
	payload: {pin_code, answer},
});

export const checkPinSuccess = () => ({
	type: PIN_ACTIONS.CHECK_PIN_SUCCESS,
});

export const checkPinError = error => ({
	type: PIN_ACTIONS.CHECK_PIN_ERROR,
	payload: {error},
});
