import keymirror from 'keymirror';

export const INITIAL_STATE = {
  error: null,
  token: null,
};

export const ACCESS_REQUEST_ACTIONS = keymirror({
  ACCESS_REQUEST: null,
  ACCESS_REQUEST_SUCCESS: null,
  ACCESS_REQUEST_ERROR: null,
});
