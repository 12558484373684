import {SIGN_IN_ACTIONS, INITIAL_STATE} from './constants';
import {REGISTRATION_ACTIONS} from '../Registration/constants';
import {PIN_ACTIONS} from '../PIN/constants';
import {USER_ACTIONS} from '../UserDashboard/constants';

const signInState = (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  const {user, error} = payload || {};
  switch(type) {
    case SIGN_IN_ACTIONS.AUTHENTICATED:
    case SIGN_IN_ACTIONS.LOGIN_REQUEST_SUCCESS:
    case REGISTRATION_ACTIONS.REGISTRATION_REQUEST_SUCCESS:
    case USER_ACTIONS.UPDATE_USER_REQUEST_SUCCESS:
    case PIN_ACTIONS.CREATE_PIN_REQUEST_SUCCESS:
      return {
        ...state,
        user,
      };
    case USER_ACTIONS.UPDATE_USER_REQUEST_ERROR:
    case SIGN_IN_ACTIONS.NOT_AUTHENTICATED:
    case SIGN_IN_ACTIONS.LOGIN_REQUEST_ERROR:
    case SIGN_IN_ACTIONS.LOGOUT_REQUEST_ERROR:
      return {
        ...state,
        error,
      };
    case REGISTRATION_ACTIONS.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case SIGN_IN_ACTIONS.LOGOUT_REQUEST_SUCCESS:
      return {
        ...state,
        user: null,
        error: null,
      };
    default:
      return state;
  }
};

export default signInState;
