import {PIN_ACTIONS, INITIAL_STATE} from "./constants";
import {REGISTRATION_ACTIONS} from '../Registration/constants';

const pinReducer = (state = INITIAL_STATE, action) => {
	const {type, payload} = action || {};
	const {error} = payload || {};

	switch (type) {
		case PIN_ACTIONS.CREATE_PIN_REQUEST_ERROR:
			return {
				...state,
				error,
			};
		case REGISTRATION_ACTIONS.CLEAR_ERROR:
			return {
				...state,
				error: null,
			};
		default:
			return state;
	}
}

export default pinReducer;