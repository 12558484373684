import axios from 'axios';
import {REACT_APP_API_URL} from '../constants';
import {getToken} from '../services/tokenCRUD';

function checkStatus(responseData) {
  if(responseData.status >= 200 && responseData.status < 300)
    return { data: responseData.data, headers: responseData.headers, status: responseData.status };

  const error = new Error((responseData.data.response && responseData.data.response.message) || '');

  error.response = responseData.data;
  throw error;
}

export function processRequest(url = '', method = 'POST', data = {}, json = true) {
  const token = getToken();
  // const connectionToken = getConnectionToken();
  let headers = {
    'Content-Type': 'application/json',
    'Authorization': token || '',
  };
  const body = json ? JSON.stringify(data) : data;

  return axios({
    method,
    data: body,
    headers,
    url: REACT_APP_API_URL + url,
    credentials: 'same-origin'
  })
  .then(res => checkStatus(res));
}
