import LOCAL_STORAGE from './LocalStorage';
import ROUTES from './Routes';
import ROUTES_WITH_BACKGROUND from './RoutesWithBackground';

const {
  REACT_APP_API_URL,
  REACT_APP_IMAGE_URL,
} = process.env;

export {
  REACT_APP_API_URL,
  REACT_APP_IMAGE_URL,
  LOCAL_STORAGE,
  ROUTES,
  ROUTES_WITH_BACKGROUND,
};
