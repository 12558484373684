import {GLOBAL_ACTIONS, INITIAL_STATE} from './constants';

const globalReducer = (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  const {notification, ws, isPinModalOpened} = payload || {};

  switch(type) {
    case GLOBAL_ACTIONS.TOGGLE_NOTIFICATION:
      return {
        ...state,
        notification,
      };
    case GLOBAL_ACTIONS.WEBSOCKET_CONNECTION:
      return {
        ...state,
        ws,
      };
    case GLOBAL_ACTIONS.TOGGLE_PIN_MODAL:
      return {
        ...state,
        isPinModalOpened,
      };
    default:
      return state;
  };
}

export default globalReducer;
