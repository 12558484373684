import {USER_ACTIONS} from './constants';

export const getQR = () => ({
  type: USER_ACTIONS.GET_QR,
});

export const getQRSuccess = qr_code => ({
  type: USER_ACTIONS.GET_QR_SUCCESS,
  payload: {qr_code},
});

export const getQRError = error => ({
  type: USER_ACTIONS.GET_QR_ERROR,
  payload: {error},
});

export const sendQR = requestData => ({
  type: USER_ACTIONS.SEND_QR,
  payload: {requestData},
});

export const sendQRSuccess = () => ({
  type: USER_ACTIONS.SEND_QR_SUCCESS,
});

export const sendQRError = error => ({
  type: USER_ACTIONS.SEND_QR_ERROR,
  payload: {error},
});

export const getHospitals = meta => ({
  type: USER_ACTIONS.GET_HOSPITALS_LIST,
  payload: {meta},
});

export const getHospitalsSuccess = (hospitals, total_pages) => ({
  type: USER_ACTIONS.GET_HOSPITALS_LIST_SUCCESS,
  payload: {hospitals, total_pages},
});

export const getHospitalsNextPageSuccess = (hospitals, total_pages) => ({
  type: USER_ACTIONS.GET_HOSPITALS_LIST_NEXT_PAGE_SUCCESS,
  payload: {hospitals, total_pages},
});

export const getHospitalsError = error => ({
  type: USER_ACTIONS.GET_HOSPITALS_LIST_ERROR,
  payload: {error},
});

export const updateUserRequest = user => ({
  type: USER_ACTIONS.UPDATE_USER_REQUEST,
  payload: {user},
});

export const updateUserRequestSuccess = user => ({
  type: USER_ACTIONS.UPDATE_USER_REQUEST_SUCCESS,
  payload: {user},
});

export const updateUserRequestError = error => ({
  type: USER_ACTIONS.UPDATE_USER_REQUEST_ERROR,
  payload: {error},
});

export const getAccessRequests = () => ({
  type: USER_ACTIONS.GET_ACCESS_REQUESTS,
});

export const getAccessRequestsSuccess = accessRequests => ({
  type: USER_ACTIONS.GET_ACCESS_REQUESTS_SUCCESS,
  payload: {accessRequests},
});

export const getAccessRequestsError = error => ({
  type: USER_ACTIONS.GET_ACCESS_REQUESTS_ERROR,
  payload: {error},
});

export const updateRequestState = requestState => ({
  type: USER_ACTIONS.UPDATE_REQUEST_STATE,
  payload: {requestState},
});

export const updateRequestStateSuccess = access_request => ({
  type: USER_ACTIONS.UPDATE_REQUEST_STATE_SUCCESS,
  payload: {access_request},
});

export const updateRequestStateError = error => ({
  type: USER_ACTIONS.UPDATE_REQUEST_STATE_ERROR,
  payload: {error},
});
