import keymirror from 'keymirror';

export const INITIAL_STATE = {
  questionare: null,
  error: null,
  category: null,
  answers_meta: {
    page: 1,
    per_page: 10,
    category_id: '',
    total_pages: 1,
  },
  answers: [],
  score_result: null,
};

export const QUESTIONARE_ACTIONS = keymirror({
  GET_QUESTIONS: null,
  GET_QUESTIONS_SUCCESS: null,
  GET_QUESTIONS_ERROR: null,
  GET_ANSWERS: null,
  GET_ANSWERS_SUCCESS: null,
  GET_ANSWERS_NEXT_PAGE_SUCCESS: null,
  GET_ANSWERS_ERROR: null,
  SAVE_ANSWER: null,
  SAVE_ANSWER_SUCCESS: null,
  SAVE_ANSWER_ERROR: null,
  EDIT_ANSWER: null,
  EDIT_ANSWER_SUCCESS: null,
  EDIT_ANSWER_ERROR: null,
  GET_SCORE_RESULT: null,
  GET_SCORE_RESULT_SUCCESS: null,
  GET_SCORE_RESULT_ERROR: null,
  CLEAR_SCORE_RESULT: null,
});
