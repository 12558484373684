import {SIGN_IN_ACTIONS} from './constants';

export const isAuthenticated = () => ({
  type: SIGN_IN_ACTIONS.IS_AUTHENTICATED,
});

export const authenticated = user => ({
  type: SIGN_IN_ACTIONS.AUTHENTICATED,
  payload: {user},
});

export const notAuthenticated = error => ({
  type: SIGN_IN_ACTIONS.NOT_AUTHENTICATED,
  payload: {error},
});

export const loginRequest = userData => ({
  type: SIGN_IN_ACTIONS.LOGIN_REQUEST,
  payload: {userData},
});

export const loginSuccess = user => ({
  type: SIGN_IN_ACTIONS.LOGIN_REQUEST_SUCCESS,
  payload: {user},
});

export const loginError = error => ({
  type: SIGN_IN_ACTIONS.LOGIN_REQUEST_ERROR,
  payload: {error},
});

export const logoutRequest = () => ({
  type: SIGN_IN_ACTIONS.LOGOUT_REQUEST,
});

export const logoutRequestSuccess = () => ({
  type: SIGN_IN_ACTIONS.LOGOUT_REQUEST_SUCCESS,
});

export const logoutRequestError = error => ({
  type: SIGN_IN_ACTIONS.LOGOUT_REQUEST_ERROR,
  payload: {error},
});
