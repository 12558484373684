const {
  REACT_APP_API_URL,
  REACT_APP_IMAGE_URL,
  REACT_APP_WS_URL,
} = process.env;
export const config = {
  REACT_APP_API_URL,
  REACT_APP_IMAGE_URL,
  REACT_APP_WS_URL,
};
