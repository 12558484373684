import {takeEvery, put, call} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {REGISTRATION_ACTIONS} from './constants';
import {ROUTES} from '../../constants';
import {
	registrationRequest,
	registrationSuccess,
	registrationError,
	phoneVerificationRequestSuccess,
	phoneVerificationRequestError,
	checkCodeRequestSuccess,
	checkCodeRequestError,
} from './actions';
import {processRequest} from '../../services/Api';
import {setToken} from '../../services/tokenCRUD';
import {setUser} from '../../services/userCRUD';

function* handleRegistration(action) {
	try {
		const {payload} = action || {};
		const {userData} = payload || {};
		const {birthday, gender, phone, full_name, email, password} = userData || {};
		const requestPayload = {
			user: {
				full_name,
				email,
				phone,
				birthday,
				gender,
				password,
			},
		};
		const {data} = yield call(processRequest, '/users', 'POST', requestPayload);
		if (data.user) {
			const user = data.user.data.attributes;
			setToken(data.token.auth_token);
			setUser(user);
			yield put(registrationSuccess(user));
			yield put(push(ROUTES.PIN));
		} else if (data.error) {
			yield put(registrationError(data.error));
		} else {
			yield put(registrationError('Sorry! Something went wrong.'))
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (error_message) {
				message = error_message;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(registrationError(message));
		} else if (status === 401) {
			yield put(registrationError(error));
		} else if (status === 500) {
			yield put(
				registrationError(statusText || 'Internal server error.'),
			);
		} else if (e.message) {
			yield put(registrationError(e.message));
		} else {
			yield put(registrationError('Internal server error.'));
		}
	}
}

function* handlePhoneVerification(action) {
	try {
		const {payload} = action || {};
		const {userData} = payload || {};
		const {phone} = userData || {};
		const requestPayload = {
			phone_verification_request: {phone},
		};
		const {data} = yield call(processRequest, '/phone_verification_requests', 'POST', requestPayload);
		if (data.message) {
			yield put(phoneVerificationRequestSuccess());
			yield put(push(ROUTES.VERIFICATION));
		} else if (data.error) {
			yield put(phoneVerificationRequestError(data.error));
		} else {
			yield put(phoneVerificationRequestError('Sorry! Something went wrong.'))
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (error_message) {
				message = error_message;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(phoneVerificationRequestError(message));
		} else if (status === 401) {
			yield put(phoneVerificationRequestError(error));
		} else if (status === 500) {
			yield put(
				phoneVerificationRequestError(statusText || 'Internal server error.'),
			);
		} else if (e.message) {
			yield put(phoneVerificationRequestError(e.message));
		} else {
			yield put(phoneVerificationRequestError('Internal server error.'));
		}
	}
}

function* handleCheckCode(action) {
	try {
		const {payload} = action || {};
		const {userData} = payload || {};
		const {phone, code} = userData || {};
		const requestPayload = {phone, code};
		const {data} = yield call(processRequest, '/phone_verification_requests/check_code', 'PUT', requestPayload);
		if (data.message) {
			yield put(registrationRequest(userData));
			yield put(checkCodeRequestSuccess());
		} else if (data.error) {
			yield put(checkCodeRequestError(data.error));
		} else {
			yield put(checkCodeRequestError('Sorry! Something went wrong.'))
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (error_message) {
				message = error_message;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(checkCodeRequestError(message));
		} else if (status === 401) {
			yield put(checkCodeRequestError(error));
		} else if (status === 500) {
			yield put(
				checkCodeRequestError(statusText || 'Internal server error.'),
			);
		} else if (e.message) {
			yield put(checkCodeRequestError(e.message));
		} else {
			yield put(checkCodeRequestError('Internal server error.'));
		}
	}
}

export function* watchRegistrationSagas() {
	yield takeEvery(REGISTRATION_ACTIONS.REGISTRATION_REQUEST, handleRegistration);
	yield takeEvery(REGISTRATION_ACTIONS.PHONE_VERIFICATION_REQUEST, handlePhoneVerification);
	yield takeEvery(REGISTRATION_ACTIONS.CHECK_CODE_REQUEST, handleCheckCode);
};