export const getErrorMessage = e => {
  const {response} = e;
  const {data, status, statusText} = response || {};
  const {error_messages, error, error_message} = data || {};
  let message = '';

  if (status === 400) {
    if (typeof error_message === 'string') {
      message = error_message;
    } else if (error_message) {
      const keys = Object.keys(error_message);
      const errorMessage = error_message[keys[0]][0];
      message = `${keys[0]} ${errorMessage}`;
    } else if (typeof error_messages === 'string') {
      message = error_messages;
    } else if (error_messages) {
      const keys = Object.keys(error_messages);
      const errorMessage = error_messages[keys[0]];

      message = error_messages && `${keys[0]} ${errorMessage[0]}`;
    }
  } else if (status === 401) {
    message = error || 'Session expired.';
  } else if (status === 404) {
    if (error_message) {
      message = error_message;
    } else {
      message = e.message;
    }
  } else if (status === 500) {
    message = statusText || 'Internal server error.';
  } else if (e.message) {
    message = e.message;
  } else {
    message = 'Network error';
  }

  return message;
}