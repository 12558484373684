import {USER_ACTIONS, INITIAL_STATE} from './constants';

const userDashboardState = (state = INITIAL_STATE, action) => {
  const {type, payload} = action || {};
  const {qr_code, hospitals, meta, total_pages, accessRequests, access_request, error} = payload || {};

  switch(type) {
    case USER_ACTIONS.GET_QR_SUCCESS:
      return {
        ...state,
        qr_code,
      };
    case USER_ACTIONS.GET_HOSPITALS_LIST:
      return {
        ...state,
        meta,
      };
    case USER_ACTIONS.GET_HOSPITALS_LIST_SUCCESS:
      return {
        ...state,
        hospitals,
        meta: {
          ...state.meta,
          total_pages,
        },
      };
    case USER_ACTIONS.GET_HOSPITALS_LIST_NEXT_PAGE_SUCCESS:
      return {
        ...state,
        hospitals: [...state.hospitals, ...hospitals],
        meta: {
          ...state.meta,
          total_pages,
        },
      };
    case USER_ACTIONS.GET_ACCESS_REQUESTS_SUCCESS:
      return {
        ...state,
        accessRequests,
      };
    case USER_ACTIONS.UPDATE_REQUEST_STATE_SUCCESS: {
      const access_requests = [...state.accessRequests];
      access_requests.forEach((accessRequest, index) => {
        if (accessRequest.id === access_request.id) {
          access_requests[index] = {
            ...access_requests[index],
            ...access_request,
          };
        }
      });
      return {
        ...state,
        accessRequests: access_requests,
      };
    }
    case USER_ACTIONS.GET_QR_ERROR:
    case USER_ACTIONS.GET_HOSPITALS_LIST_ERROR:
    case USER_ACTIONS.GET_ACCESS_REQUESTS_ERROR:
    case USER_ACTIONS.UPDATE_REQUEST_STATE_ERROR:
      return {
        ...state,
        error,
      };
    default:
      return state;
  }
}

export default userDashboardState;
