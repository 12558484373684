import {takeEvery, put, call, select} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {ROUTES} from '../../constants';
import {PIN_ACTIONS} from './constants';
import {
	createPinSuccess,
	createPinError,
	checkPinSuccess,
	checkPinError,
} from './actions';
import {saveAnswer, editAnswer} from '../Questionare/actions';
import {toggleNotification, togglePinModal} from '../PageWrapper/actions';
import {processRequest} from '../../services/Api';
import {setUser} from '../../services/userCRUD';
import {getUser} from '../../selectors';
import {getErrorMessage} from '../../services/SagaErrorHandler';

function* handleCreatePin(action) {
	try {
		const {payload} = action || {};
		const {pin_code} = payload || {};
		const user = yield select(getUser);
		const requestPayload = {
			user: {pin_code},
		};
		const {data} = yield call(processRequest, `/users/${user.id}`, 'PATCH', requestPayload);
		if (data.user) {
			const user = data.user.data.attributes;
			setUser(user);
			yield put(createPinSuccess(user));
			yield put(push(ROUTES.USER_DASHBOARD));
		} else if (data.error) {
			yield put(createPinError(data.error));
		} else {
			yield put(createPinError('Sorry! Something went wrong.'))
		}
	} catch (e) {
		const error_message = getErrorMessage(e);
		yield put(createPinError(error_message));
		yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}));
	}
}

function* handleCheckPin(action) {
	try {
		const {payload} = action || {};
		const {pin_code, answer} = payload || {};
		const requestPayload = {
			user: {pin_code}
		};
		const {data} = yield call(processRequest, '/users/check_pin_code', 'POST', requestPayload);
		if (data.message && data.message === 'Acces confirmed') {
			yield put(checkPinSuccess());
			yield put(togglePinModal(false));
			if (!answer) {
				yield put(push(ROUTES.USER_DASHBOARD));
			}
			if (answer) {
				if (answer.id) {
					yield put(editAnswer(answer));
				} else {
					yield put(saveAnswer(answer));
				}
			}
		} else {
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch(e) {
		const error_message = getErrorMessage(e);
		yield put(checkPinError(error_message));
		yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}));
	}
}

export function* watchPinSagas() {
	yield takeEvery(PIN_ACTIONS.CREATE_PIN_REQUEST, handleCreatePin);
	yield takeEvery(PIN_ACTIONS.CHECK_PIN, handleCheckPin);
};