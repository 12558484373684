import {FORGOT_PASSWORD_ACTIONS} from './constants';

export const forgotPassword = email => ({
  type: FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD,
  payload: {email},
});

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD_SUCCESS,
});

export const forgotPasswordError = error => ({
  type: FORGOT_PASSWORD_ACTIONS.FORGOT_PASSWORD_ERROR,
  payload: {error},
});
