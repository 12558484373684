import {takeEvery, put, call} from 'redux-saga/effects';
import {push, goBack} from 'connected-react-router';
import {ROUTES} from '../../constants';
import {USER_ACTIONS} from './constants';
import {
	getQRSuccess,
	getQRError,
	sendQRSuccess,
	sendQRError,
	getHospitalsSuccess,
	getHospitalsNextPageSuccess,
	getHospitalsError,
	updateUserRequestSuccess,
	updateUserRequestError,
	getAccessRequestsSuccess,
	getAccessRequestsError,
	updateRequestStateSuccess,
	updateRequestStateError,
} from './actions';
import {toggleNotification} from '../PageWrapper/actions';
import {processRequest} from '../../services/Api';
import {getErrorMessage} from '../../services/SagaErrorHandler';
import {setUser} from '../../services/userCRUD';

function* handleGetQR() {
  try {
		const {data} = yield call(processRequest, `/share_objects`, 'POST');
		if (data.share_object) {
      const {attributes} = data.share_object.data;
			yield put(getQRSuccess(attributes));
			yield put(push(ROUTES.SHARE_QR));
		} else if (data.error) {
			yield put(getQRError(data.error));
			yield put(toggleNotification({title: 'Error', message: data.error, type: 'danger'}));
		} else {
			yield put(getQRError('Sorry! Something went wrong.'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (typeof error_message === 'string') {
				message = error_message;
			} else if (error_message) {
				const keys = Object.keys(error_message);
				const errorMessage = error_message[keys[0]][0];
				message = `${keys[0]} ${errorMessage}`;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(getQRError(message));
			yield put(toggleNotification({title: 'Sorry!', message: message || 'Something went wrong.', type: 'danger'}));
		} else if (status === 401) {
			yield put(getQRError(error));
			yield put(push(ROUTES.HOME));
		} else if (status === 404) {
      if (error_message) {
				yield put(getQRError(error_message));
				yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}));
			} else {
				yield put(getQRError(e.message));
				yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
			}
    } else if (status === 500) {
			yield put(
				getQRError(statusText || 'Internal server error.'),
			);
			yield put(toggleNotification({title: 'Sorry!', message: statusText || 'Internal server error.', type: 'danger'}));
		} else if (e.message) {
			yield put(getQRError(e.message));
			yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
		} else {
			yield put(getQRError('Network error'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Network error', type: 'danger'}));
		}
	}
}

function* handleSendQR(action) {
	try {
		const {payload} = action || {};
		const {requestData} = payload || {};
		const {email, qrId} = requestData || {};
		const requestPayload = {email};
		const {data} = yield call(processRequest, `/share_objects/send_to_email/${qrId}`, 'POST', requestPayload);
		if (data.message) {
			yield put(sendQRSuccess());
			yield put(goBack());
			yield put(toggleNotification({title: 'Success', message: data.message, type: 'success'}));
		} else if (data.error) {
			yield put(sendQRError(data.error));
			yield put(toggleNotification({title: 'Error', message: data.error, type: 'danger'}));
		} else {
			yield put(sendQRError('Sorry! Something went wrong.'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch (e) {
		const {response} = e;
		const {data, status, statusText} = response || {};
		const {error_messages, error, error_message} = data || {};

		if (status === 400) {
			let message = '';
			if (typeof error_message === 'string') {
				message = error_message;
			} else if (error_message) {
				const keys = Object.keys(error_message);
				const errorMessage = error_message[keys[0]][0];
				message = `${keys[0]} ${errorMessage}`;
			} else if (typeof error_messages === 'string') {
				message = error_messages;
			} else if (error_messages) {
				const keys = Object.keys(error_messages);
				const errorMessage = error_messages[keys[0]];

				message = error_messages && `${keys[0]} ${errorMessage}`;
			}

			yield put(sendQRError(message));
			yield put(toggleNotification({title: 'Sorry!', message: message || 'Something went wrong.', type: 'danger'}));
		} else if (status === 401) {
			yield put(sendQRError(error));
			yield put(push(ROUTES.HOME));
		} else if (status === 404) {
      if (error_message) {
				yield put(sendQRError(error_message));
				yield put(toggleNotification({title: 'Sorry!', message: error_message, type: 'danger'}));
			} else {
				yield put(sendQRError(e.message));
				yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
			}
    } else if (status === 500) {
			yield put(
				sendQRError(statusText || 'Internal server error.'),
			);
			yield put(toggleNotification({title: 'Sorry!', message: statusText || 'Internal server error.', type: 'danger'}));
		} else if (e.message) {
			yield put(sendQRError(e.message));
			yield put(toggleNotification({title: 'Sorry!', message: e.message, type: 'danger'}));
		} else {
			yield put(sendQRError('Network error'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Network error', type: 'danger'}));
		}
	}
}

function* handleGetHospitals(action) {
	try {
		const {payload} = action || {};
		const {meta} = payload || {};
		const {page, per_page} = meta;
		const getHospitalsListSuccess = page === 1 ? getHospitalsSuccess : getHospitalsNextPageSuccess;
		const {data} = yield call(processRequest, `/hospitals?page=${page}&per_page=${per_page}`, 'GET');
		if (data.hospital) {
			const hospitals_list = data.hospital.data.map(hospital => hospital.attributes);
			yield put(getHospitalsListSuccess(hospitals_list, data.total_pages));
		} else if (data.error) {
			yield put(getHospitalsError(data.error));
		} else {
			yield put(getHospitalsError('Sorry! Something went wrong.'));
		}
	} catch (e) {
		const error = getErrorMessage(e);
		yield put(getHospitalsError(error));
	}
}

function* handleUpdateUser(action) {
	try {
		const {payload} = action || {};
		const {user} = payload || {};
		const {data} = yield call(processRequest, `/users/${user.id}`, 'PATCH', user);
		if (data.user) {
			const user = data.user.data.attributes;
			setUser(user);
			yield put(updateUserRequestSuccess(user));
			yield put(toggleNotification({title: 'Success', message: ' ', type: 'success'}));
		} else if (data.error) {
			yield put(updateUserRequestError(data.error));
			yield put(toggleNotification({title: 'Sorry!', message: data.error, type: 'danger'}));
		} else {
			yield put(getHospitalsError('Sorry! Something went wrong.'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch (e) {
		const error = getErrorMessage(e);
		console.log(e, error)
		yield put(updateUserRequestError(error));
		yield put(toggleNotification({title: 'Sorry!', message: error, type: 'danger'}));
	}
}

function* handleGetAccessRequests() {
	try {
		const {data} = yield call(processRequest, `/access_requests`, 'GET');
		if (data.access_request) {
			const access_request = data.access_request.data.map(accessRequest => accessRequest.attributes);
			yield put(getAccessRequestsSuccess(access_request));
		} else if (data.error) {
			yield put(getAccessRequestsError(data.error));
		} else {
			yield put(getAccessRequestsError('Sorry! Something went wrong.'));
		}
	} catch (e) {
		const error = getErrorMessage(e);
		yield put(getAccessRequestsError(error));
	}
}

function *handleUpdateRequestState(action) {
	try {
		const {payload} = action || {};
		const {requestState} = payload || {};
		const requestPayload = {
			state: requestState.state,
		};
		const {data} = yield call(processRequest, `/access_requests/${requestState.id}`, 'PUT', requestPayload);
		if (data.access_request) {
			const access_request = data.access_request.data.attributes;
			yield put(updateRequestStateSuccess(access_request));
			yield put(toggleNotification({
				title: 'Success',
				message: `Request successfully ${requestState.state}`,
				type: 'success',
			}));
		} else if (data.error) {
			yield put(updateRequestStateError(data.error));
			yield put(toggleNotification({title: 'Sorry!', message: data.error, type: 'danger'}));
		} else {
			yield put(updateRequestStateError('Sorry! Something went wrong.'));
			yield put(toggleNotification({title: 'Sorry!', message: 'Something went wrong.', type: 'danger'}));
		}
	} catch (e) {
		const error = getErrorMessage(e);
		yield put(updateRequestStateError(error));
		yield put(toggleNotification({title: 'Sorry!', message: error, type: 'danger'}));
	}
}

export function* watchUserSagas() {
	yield takeEvery(USER_ACTIONS.GET_QR, handleGetQR);
	yield takeEvery(USER_ACTIONS.SEND_QR, handleSendQR);
	yield takeEvery(USER_ACTIONS.GET_HOSPITALS_LIST, handleGetHospitals);
	yield takeEvery(USER_ACTIONS.UPDATE_USER_REQUEST, handleUpdateUser);
	yield takeEvery(USER_ACTIONS.GET_ACCESS_REQUESTS, handleGetAccessRequests);
	yield takeEvery(USER_ACTIONS.UPDATE_REQUEST_STATE, handleUpdateRequestState);
};
