import React, {useRef} from 'react';
import './pin.css'

const PinInput = ({handleChange, pin_code, onKeyDown}) => {
	const input_0 = useRef(null);
	const input_1 = useRef(null);
	const input_2 = useRef(null);
	const input_3 = useRef(null);

	const handleChangePin = event => {
		const index = Number(event.target.name) - 1;
		const value = event.target.value;
		const newPin = pin_code[index] ? pin_code[index].replace(pin_code[index], value) : pin_code + value;
		handleChange(newPin);
		if (value) {
			switch(index) {
				case 0:
					input_1.current.focus();
					break;
				case 1:
					input_2.current.focus();
					break;
				case 2:
					input_3.current.focus();
					break;
				case 3:
					break;
				default:
					break;
			}
		}
	};
	return (
    <div className="password__section">
      <input
        ref={input_0}
        type="password"
        name="1"
        className="pincode"
        inputMode="numeric"
        maxLength="1"
        onChange={handleChangePin}
      />
      <input
        ref={input_1}
        type="password"
        name="2"
        className="pincode"
        inputMode="numeric"
        maxLength="1"
        onChange={handleChangePin}
      />
      <input
        ref={input_2}
        type="password"
        name="3"
        className="pincode"
        inputMode="numeric"
        maxLength="1"
        onChange={handleChangePin}
      />
      <input
        ref={input_3}
        type="password"
        name="4"
        className="pincode"
        inputMode="numeric"
        maxLength="1"
        onChange={handleChangePin}
        onKeyDown={onKeyDown}
      />
    </div>
	);
}

export default PinInput;